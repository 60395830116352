@font-face {
  font-family: "museo-sans-300";
  font-weight: 300;
  src: url("../fonts/MuseoSans_300.eot?") format("eot"), url("../fonts/MuseoSans_300.woff2") format("woff2"), url("../fonts/MuseoSans_300.woff") format("woff"), url("../fonts/MuseoSans_300.ttf") format("truetype"), url("../fonts/MuseoSans_300.svg#museo-sans-300") format("svg"); }

@font-face {
  font-family: "museo-sans-300";
  font-style: italic;
  font-weight: 300;
  src: url("../fonts/MuseoSans_300_Italic.eot?") format("eot"), url("../fonts/MuseoSans_300_Italic.woff2") format("woff2"), url("../fonts/MuseoSans_300_Italic.woff") format("woff"), url("../fonts/MuseoSans_300_Italic.ttf") format("truetype"), url("../fonts/MuseoSans_300_Italic.svg#museo-sans-300") format("svg"); }

@font-face {
  font-family: "museo-sans-300";
  font-style: italic;
  font-weight: 700;
  src: url("../fonts/MuseoSans_700_Italic.eot?") format("eot"), url("../fonts/MuseoSans_700_Italic.woff2") format("woff2"), url("../fonts/MuseoSans_700_Italic.woff") format("woff"), url("../fonts/MuseoSans_700_Italic.ttf") format("truetype"), url("../fonts/MuseoSans_700_Italic.svg#museo-sans-300") format("svg"); }

@font-face {
  font-family: "museo-sans-300";
  font-weight: 700;
  src: url("../fonts/MuseoSans_700.eot?") format("eot"), url("../fonts/MuseoSans_700.woff2") format("woff2"), url("../fonts/MuseoSans_700.woff") format("woff"), url("../fonts/MuseoSans_700.ttf") format("truetype"), url("../fonts/MuseoSans_700.svg#museo-sans-300") format("svg"); }

@font-face {
  font-family: "museo-sans-500";
  font-weight: 500;
  src: url("../fonts/MuseoSans_500.eot?") format("eot"), url("../fonts/MuseoSans_500.woff2") format("woff2"), url("../fonts/MuseoSans_500.woff") format("woff"), url("../fonts/MuseoSans_500.ttf") format("truetype"), url("../fonts/MuseoSans_500.svg#museo-sans-500") format("svg"); }

@font-face {
  font-family: "museo-sans-900";
  font-weight: 900;
  src: url("../fonts/MuseoSans_900.eot?") format("eot"), url("../fonts/MuseoSans_900.woff2") format("woff2"), url("../fonts/MuseoSans_900.woff") format("woff"), url("../fonts/MuseoSans_900.ttf") format("truetype"), url("../fonts/MuseoSans_900.svg#museo-sans-900") format("svg"); }

@font-face {
  font-family: "officiana-serif-black-itc";
  font-weight: "bold";
  src: url("../fonts/OfficinaSerifBlackITC-Regular.eot?") format("eot"), url("../fonts/OfficinaSerifBlackITC-Regular.woff2") format("woff2"), url("../fonts/OfficinaSerifBlackITC-Regular.woff") format("woff"), url("../fonts/OfficinaSerifBlackITC-Regular.ttf") format("truetype"), url("../fonts/OfficinaSerifBlackITC-Regular.svg#officiana-serif-black-itc") format("svg"); }

@font-face {
  font-family: "officiana-sans-black-itc";
  font-weight: "bold";
  src: url("../fonts/OfficinaSansExtraBoldITC-Regular.eot?") format("eot"), url("../fonts/OfficinaSansExtraBoldITC-Regular.woff2") format("woff2"), url("../fonts/OfficinaSansExtraBoldITC-Regular.woff") format("woff"), url("../fonts/OfficinaSansExtraBoldITC-Regular.ttf") format("truetype"), url("../fonts/OfficinaSansExtraBoldITC-Regular.svg#officiana-sans-black-itc") format("svg"); }

html, body {
  margin: 0 auto;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-family: "museo-sans-300", sans-serif;
  font-weight: 300;
  background: #ffffff; }

a {
  color: #F47920; }
  a:hover {
    text-decoration: none; }

b, strong {
  font-weight: 700; }

.burger {
  display: none;
  position: relative;
  width: 68px;
  height: 68px;
  background: #ffffff;
  -webkit-transition: all .25s ease;
  -moz-transition: all .25s ease;
  -o-transition: all .25s ease;
  transition: all .25s ease;
  float: right;
  margin: 15px 0 0; }
  .burger:before, .burger:after {
    content: '';
    display: block;
    position: absolute;
    width: 20px;
    height: 4px;
    left: 25px;
    background: #231F20;
    top: 32px;
    -webkit-transition: all .25s ease;
    -moz-transition: all .25s ease;
    -o-transition: all .25s ease;
    transition: all .25s ease;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px; }
  .burger:after {
    top: 40px; }
  .burger span {
    display: block;
    position: absolute;
    width: 20px;
    height: 4px;
    left: 25px;
    background: #231F20;
    top: 24px;
    -webkit-transition: all .25s ease;
    -moz-transition: all .25s ease;
    -o-transition: all .25s ease;
    transition: all .25s ease;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px; }
  .burger.open:before {
    -webkit-transform: rotate(-45deg) translate(-10px, 9px);
    -moz-transform: rotate(-45deg) translate(-10px, 9px);
    -ms-transform: rotate(-45deg) translate(-10px, 9px);
    -o-transform: rotate(-45deg) translate(-10px, 9px);
    transform: rotate(-45deg) translate(-10px, 9px);
    -webkit-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    -o-transform-origin: 0 0;
    transform-origin: 0 0;
    width: 37px;
    background: #252525;
    left: 22px; }
  .burger.open:after {
    -webkit-transform: rotate(45deg) translate(-13px, -16px);
    -moz-transform: rotate(45deg) translate(-13px, -16px);
    -ms-transform: rotate(45deg) translate(-13px, -16px);
    -o-transform: rotate(45deg) translate(-13px, -16px);
    transform: rotate(45deg) translate(-13px, -16px);
    -webkit-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    -o-transform-origin: 0 0;
    transform-origin: 0 0;
    width: 37px;
    background: #252525;
    left: 22px; }
  .burger.open span {
    width: 0;
    top: 32px;
    height: 0;
    left: 32px; }
  @media screen and (max-width: 820px) {
    .burger {
      display: block; } }

header {
  margin: 0 auto;
  width: 100%;
  position: fixed;
  background: #ffffff;
  top: 0;
  z-index: 10; }
  header section {
    margin: 0 auto;
    max-width: 1200px;
    width: 100%;
    height: 150px;
    padding: 0;
    vertical-align: top;
    -webkit-transition: height .25s ease;
    -moz-transition: height .25s ease;
    -o-transition: height .25s ease;
    transition: height .25s ease;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position: relative; }
  header.small section {
    height: 100px; }
    header.small section .logo {
      width: 70px; }
      header.small section .logo img {
        margin-top: 15px; }
    header.small section nav ul {
      margin-top: 34px; }
    header.small section .support {
      margin-top: 14px; }
  @media screen and (max-width: 1090px) {
    header section {
      height: 100px; }
      header section .logo img {
        margin-top: 15px; }
      header section nav ul {
        margin-top: 34px; }
      header section .support {
        margin-top: 14px; } }
  @media screen and (max-width: 380px) {
    header section .logo, header.small section .logo {
      width: 50px; }
      header section .logo img, header.small section .logo img {
        margin-top: 28px; } }

.logo {
  display: inline-block;
  width: 100px;
  height: inherit;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  text-decoration: none;
  outline: none;
  vertical-align: top;
  -webkit-transition: all .25s ease;
  -moz-transition: all .25s ease;
  -o-transition: all .25s ease;
  transition: all .25s ease;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  padding-left: 50px; }
  .logo img {
    display: block;
    width: 100%;
    margin-top: 22px;
    -webkit-transition: all .25s ease;
    -moz-transition: all .25s ease;
    -o-transition: all .25s ease;
    transition: all .25s ease; }
  .logo:focus {
    outline: 1px dotted #231F20; }
  @media screen and (max-width: 1090px) {
    .logo {
      width: 70px; } }

nav {
  display: inline-block; }
  nav ul {
    list-style: none;
    margin: 52px 0 0 100px;
    padding: 0;
    -webkit-transition: all .25s ease;
    -moz-transition: all .25s ease;
    -o-transition: all .25s ease;
    transition: all .25s ease; }
    nav ul li {
      display: inline-block;
      position: relative;
      margin-right: 40px; }
      nav ul li a {
        text-decoration: none;
        display: block;
        font-weight: 300;
        color: #231F20;
        font-size: 20px;
        outline: none;
        margin: 0 auto;
        line-height: 20px;
        padding: 0 6px;
        text-transform: capitalize; }
        nav ul li a:after {
          content: '';
          display: block;
          width: 10px;
          height: 4px;
          -webkit-border-radius: 2px;
          -moz-border-radius: 2px;
          border-radius: 2px;
          background: #231F20;
          margin: 20px auto 0;
          -webkit-transition: all .25s ease;
          -moz-transition: all .25s ease;
          -o-transition: all .25s ease;
          transition: all .25s ease; }
        nav ul li a:hover, nav ul li a.active {
          color: #F47920; }
          nav ul li a:hover:after, nav ul li a.active:after {
            background: #F47920;
            width: 100%;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            border-radius: 0; }
        nav ul li a span {
          -webkit-transition: all .25s ease;
          -moz-transition: all .25s ease;
          -o-transition: all .25s ease;
          transition: all .25s ease;
          max-width: 150px;
          display: inline-block;
          overflow: hidden;
          height: 22px;
          vertical-align: middle; }
          nav ul li a span.mob {
            max-width: 0; }
          @media screen and (max-width: 1090px) {
            nav ul li a span {
              max-width: 0; }
              nav ul li a span.mob {
                max-width: 100px; } }
    @media screen and (max-width: 1150px) {
      nav ul {
        margin-left: 10px; }
        nav ul li {
          margin-right: 20px; } }
  @media screen and (max-width: 820px) {
    nav {
      overflow: hidden;
      position: absolute;
      right: 0;
      top: 100px;
      background: #ffffff;
      width: 100%;
      max-width: 400px;
      -webkit-transition: all .25s ease-in;
      -moz-transition: all .25s ease-in;
      -o-transition: all .25s ease-in;
      transition: all .25s ease-in;
      max-height: 0; }
      nav ul {
        margin-left: 0;
        padding-bottom: 50px;
        padding-top: 20px; }
        nav ul li {
          display: block;
          text-align: center;
          padding-top: 10px;
          padding-bottom: 6px;
          margin-right: 0; }
          nav ul li a:after {
            margin-top: 10px;
            width: 50%; }
          nav ul li a:hover:after {
            width: 60%; }
          nav ul li:last-of-type a:after {
            display: none; }
      nav.open {
        -webkit-box-shadow: #231F20 0 0 1px 0;
        -moz-box-shadow: #231F20 0 0 1px 0;
        box-shadow: #231F20 0 0 1px 0;
        max-height: 500px; } }

.support {
  display: block;
  float: right;
  width: 300px;
  height: 70px;
  font-family: "officiana-serif-black-itc", sans-serif;
  font-weight: bold;
  color: #F47920;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  border: 4px #F47920 solid;
  text-align: center;
  text-decoration: none;
  font-size: 25px;
  line-height: 25px;
  padding-top: 18px;
  margin-top: 36px;
  margin-right: 50px;
  position: relative;
  -webkit-transition: all .25s ease;
  -moz-transition: all .25s ease;
  -o-transition: all .25s ease;
  transition: all .25s ease; }
  .support:before {
    content: '';
    display: block;
    position: absolute;
    width: 8px;
    height: 8px;
    border-bottom: 2px solid #F47920;
    border-right: 2px solid #F47920;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    right: 20px;
    top: 29px; }
  .support:after {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 14px;
    border-right: 2px solid #F47920;
    right: 24px;
    top: 24px; }
  .support:hover {
    background: #F47920;
    color: #ffffff; }
    .support:hover:before, .support:hover:after {
      border-color: #ffffff; }
  @media screen and (max-width: 1090px) {
    .support {
      width: 160px;
      margin-right: 20px;
      text-align: left;
      padding-left: 15px; }
      .support:after {
        right: 19px; }
      .support:before {
        right: 15px; } }
  @media screen and (max-width: 820px) {
    .support {
      margin-right: 0; } }

.blocks {
  background: #ffffff url("../img/bg.jpg") center top no-repeat;
  width: 100%;
  max-width: 1200px;
  margin: 150px auto 0;
  padding-bottom: 2px;
  border-bottom: 8px #D3D2D1 solid;
  position: relative; }
  .blocks:after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background: #B5B3B1;
    position: absolute;
    left: 0;
    bottom: 0; }
  .blocks .main {
    width: 100%;
    background: rgba(255, 205, 3, 0.85);
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0 auto; }
    .blocks .main .smile {
      display: block;
      width: 30%;
      background: transparent url("../img/smile.png") center center no-repeat;
      min-height: 300px;
      float: left; }
      @media screen and (max-width: 920px) {
        .blocks .main .smile {
          display: none; } }
    .blocks .main .text {
      float: left;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      width: 70%;
      padding-right: 50px;
      padding-bottom: 30px; }
      .blocks .main .text h1 {
        width: 80%;
        max-width: 500px;
        font-family: "museo-sans-900", sans-serif;
        font-weight: 900;
        margin: 60px 0 40px;
        font-size: 30px;
        color: #F47920; }
      .blocks .main .text .description {
        width: 80%;
        max-width: 500px;
        font-size: 16px;
        color: #231F20;
        -webkit-transition: all .5s ease;
        -moz-transition: all .5s ease;
        -o-transition: all .5s ease;
        transition: all .5s ease;
        overflow: hidden;
        max-height: 0; }
        .blocks .main .text .description p, .blocks .main .text .description section.inner article .meta, section.inner article .blocks .main .text .description .meta {
          margin: 0 0 30px; }
        .blocks .main .text .description.open {
          max-height: 1000px; }
      @media screen and (max-width: 920px) {
        .blocks .main .text {
          width: 100%;
          margin: 0 auto;
          padding-right: 0; }
          .blocks .main .text h1 {
            margin: 60px auto 40px;
            width: 100%;
            max-width: 700px;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            padding: 0 30px; }
          .blocks .main .text .description {
            max-width: 700px;
            margin: 0 auto;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            width: 100%;
            padding: 0 30px; }
          .blocks .main .text .main-more {
            margin-right: 30px; } }
      @media screen and (max-width: 580px) {
        .blocks .main .text h1 {
          font-size: 22px;
          margin-bottom: 30px; }
        .blocks .main .text .main-more {
          float: left;
          margin-left: 35px; } }
  .blocks .block-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .blocks .block {
    width: 33.3333333%;
    float: left;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
    padding: 40px 50px 30px;
    color: #ffffff;
    opacity: 0;
    -webkit-transition: opacity .5s ease-in;
    -moz-transition: opacity .5s ease-in;
    -o-transition: opacity .5s ease-in;
    transition: opacity .5s ease-in; }
    .blocks .block.show {
      opacity: 1; }
    .blocks .block h1 {
      font-size: 30px;
      font-weight: bold;
      font-family: "officiana-serif-black-itc", sans-serif;
      margin: 0;
      padding: 0;
      display: block;
      -webkit-box-flex: 0;
      -webkit-flex: 0;
      -moz-box-flex: 0;
      -ms-flex: 0;
      flex: 0; }
    .blocks .block .intro {
      margin-top: 10px;
      color: #ffffff;
      font-size: 16px;
      padding-bottom: 60px; }
      .blocks .block .intro p, .blocks .block .intro section.inner article .meta, section.inner article .blocks .block .intro .meta {
        margin: 0; }
    .blocks .block.orange, .blocks .forms .form-group button.block, .forms .form-group .blocks button.block {
      background: rgba(241, 131, 33, 0.95); }
    .blocks .block.blue {
      background: rgba(87, 198, 213, 0.95); }
    .blocks .block.violet {
      background: rgba(174, 107, 172, 0.95); }
    .blocks .block.red {
      background: rgba(237, 0, 162, 0.95); }
    .blocks .block.brown {
      background: rgba(197, 135, 101, 0.95); }
    .blocks .block.green {
      background: rgba(109, 168, 93, 0.95); }
    @media screen and (max-width: 860px) {
      .blocks .block {
        width: 50%; } }
    @media screen and (max-width: 490px) {
      .blocks .block {
        width: 100%; } }
  @media screen and (max-width: 1090px) {
    .blocks {
      margin-top: 100px; } }
  @media screen and (min-width: 1200px) {
    .blocks {
      max-width: 1600px;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover; } }

.button, .forms .form-group button {
  display: block;
  border: 1px solid #ffffff;
  font-size: 16px;
  position: relative;
  color: #ffffff;
  padding: 7px 12px 6px 7px;
  text-decoration: none;
  outline: none;
  float: left; }
  .button:before, .forms .form-group button:before {
    content: '';
    width: 100%;
    height: 0;
    background: #ffffff;
    position: absolute;
    bottom: 0;
    left: 0;
    -webkit-transition: all .25s ease;
    -moz-transition: all .25s ease;
    -o-transition: all .25s ease;
    transition: all .25s ease; }
  .button:hover:before, .forms .form-group button:hover:before {
    height: 4px;
    bottom: -8px; }
  .button:after, .forms .form-group button:after {
    content: '';
    background: transparent url("../img/arrow-white-right.png") center center no-repeat;
    width: 16px;
    height: 16px;
    display: inline-block;
    margin-left: 10px;
    margin-top: -3px;
    -webkit-transition: all .25s ease;
    -moz-transition: all .25s ease;
    -o-transition: all .25s ease;
    transition: all .25s ease; }
  .button.orange, .forms .form-group button {
    border-color: orange;
    color: #F47920; }
    .button.orange span, .forms .form-group button span {
      max-height: 50px;
      -webkit-transition: all .25s ease;
      -moz-transition: all .25s ease;
      -o-transition: all .25s ease;
      transition: all .25s ease;
      overflow: hidden;
      display: inline-block; }
    .button.orange:before, .forms .form-group button:before {
      background: orange; }
    .button.orange:after, .forms .form-group button:after {
      background-image: url("../img/arrow-orange-down.png"); }
    .button.orange.up span, .forms .form-group button.up span {
      max-height: 0;
      max-width: 0; }
    .button.orange.up:after, .forms .form-group button.up:after {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg);
      margin-left: 7px;
      margin-top: 0; }
    .button.orange.forward:after, .forms .form-group button:after {
      -webkit-transform: rotate(-90deg);
      -moz-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      -o-transform: rotate(-90deg);
      transform: rotate(-90deg);
      vertical-align: middle; }
  .button.aling-rigth, .forms .form-group button.aling-rigth {
    float: right; }
  .button.absolute, .forms .form-group button.absolute {
    position: absolute; }
    .button.absolute.bottom, .forms .form-group button.absolute.bottom {
      left: 50px;
      bottom: 30px; }
  .button.archive, .forms .form-group button.archive {
    position: absolute;
    left: 50%;
    bottom: -15px;
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    white-space: nowrap; }

section.help {
  background: #ffffff;
  color: #ED6A6B;
  font-size: 80px;
  position: relative;
  text-align: center;
  font-family: "officiana-sans-black-itc", sans-serif;
  font-weight: bold;
  width: 100%;
  max-width: 1200px;
  padding: 80px 50px;
  margin: 100px auto 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  opacity: 0;
  -webkit-transition: all .5s ease-in;
  -moz-transition: all .5s ease-in;
  -o-transition: all .5s ease-in;
  transition: all .5s ease-in; }
  section.help.show {
    opacity: 1;
    margin-top: 0; }
  @media screen and (max-width: 768px) {
    section.help {
      padding: 50px; } }
  @media screen and (max-width: 520px) {
    section.help {
      font-size: 55px;
      padding: 40px; } }
  section.help span {
    text-transform: uppercase;
    display: inline-block;
    position: relative; }
    section.help span:after {
      content: '';
      position: absolute;
      width: 100%;
      bottom: 3px;
      height: 8px;
      background: #ED6A6B;
      left: 0; }

.help-blocks {
  background: #ffffff;
  position: relative;
  width: 100%;
  max-width: 1200px;
  padding: 0 30px;
  margin: 100px auto 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  opacity: 0;
  -webkit-transition: all .5s ease-in;
  -moz-transition: all .5s ease-in;
  -o-transition: all .5s ease-in;
  transition: all .5s ease-in; }
  .help-blocks.show {
    opacity: 1;
    margin-top: 0; }
  @media screen and (max-width: 920px) {
    .help-blocks {
      padding: 0; } }
  .help-blocks .column {
    float: left;
    width: 50%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
    padding-top: 62px;
    height: 332px; }
    @media screen and (max-width: 670px) {
      .help-blocks .column {
        width: 100%; } }
    .help-blocks .column p, .help-blocks .column section.inner article .meta, section.inner article .help-blocks .column .meta {
      font-family: "museo-sans-900", sans-serif;
      font-size: 30px;
      padding: 0;
      margin: 0;
      color: #F47920;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box; }
      .help-blocks .column p.phone, .help-blocks .column section.inner article .phone.meta, section.inner article .help-blocks .column .phone.meta {
        padding: 60px 15px 10px 20px;
        border-bottom: 3px #F47920 solid;
        display: inline-block; }
    .help-blocks .column.call {
      background: transparent url("../img/call-bg.jpg") right -1px no-repeat;
      padding-left: 20px; }
    .help-blocks .column.donate {
      background: transparent url("../img/donate-bg.jpg") left -1px no-repeat;
      padding-left: 50px; }
      .help-blocks .column.donate .support {
        border-width: 3px;
        float: left;
        width: auto;
        padding-left: 30px;
        padding-right: 95px;
        margin-top: 39px; }
        .help-blocks .column.donate .support:after {
          -webkit-transform: rotate(90deg);
          -moz-transform: rotate(90deg);
          -ms-transform: rotate(90deg);
          -o-transform: rotate(90deg);
          transform: rotate(90deg);
          right: 26px; }
        .help-blocks .column.donate .support:before {
          -webkit-transform: rotate(-45deg);
          -moz-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
          -o-transform: rotate(-45deg);
          transform: rotate(-45deg);
          top: 26px; }
    @media screen and (max-width: 670px) {
      .help-blocks .column {
        width: 100%;
        float: none;
        margin: 0 auto;
        padding-left: 0; }
        .help-blocks .column p, .help-blocks .column section.inner article .meta, section.inner article .help-blocks .column .meta {
          margin: 0 auto;
          width: 100%;
          max-width: 500px;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          padding: 0 30px; }
          .help-blocks .column p.phone, .help-blocks .column section.inner article .phone.meta, section.inner article .help-blocks .column .phone.meta {
            padding: 60px 35px 10px 45px;
            border-bottom: 3px #F47920 solid;
            display: block; }
        .help-blocks .column.call, .help-blocks .column.donate {
          width: 100%;
          background-position: center -1px;
          padding-left: 0; }
          .help-blocks .column.call p, .help-blocks .column.call section.inner article .meta, section.inner article .help-blocks .column.call .meta, .help-blocks .column.donate p, .help-blocks .column.donate section.inner article .meta, section.inner article .help-blocks .column.donate .meta {
            padding-left: 50px; }
        .help-blocks .column.donate {
          padding-left: 0; }
          .help-blocks .column.donate .support {
            margin-left: 50px; } }
    @media screen and (max-width: 400px) {
      .help-blocks .column p, .help-blocks .column section.inner article .meta, section.inner article .help-blocks .column .meta {
        font-size: 22px; } }

section.helpers {
  background: #ffffff;
  position: relative;
  width: 100%;
  max-width: 1200px;
  padding: 60px 30px 0;
  margin: 100px auto 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  opacity: 0;
  -webkit-transition: all .5s ease-in;
  -moz-transition: all .5s ease-in;
  -o-transition: all .5s ease-in;
  transition: all .5s ease-in; }
  section.helpers.show {
    opacity: 1;
    margin-top: 0; }
  section.helpers .logos {
    width: 80%;
    float: right;
    padding: 60px 20px 40px 180px;
    background: #F8F2B2;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position: relative; }
    section.helpers .logos .item {
      width: 100%;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box; }
      section.helpers .logos .item.hidden {
        display: none; }
      section.helpers .logos .item .img {
        width: 170px;
        height: 200px;
        background: transparent center top no-repeat;
        float: left; }
        @media screen and (max-width: 500px) {
          section.helpers .logos .item .img {
            display: none; } }
      section.helpers .logos .item .data {
        padding-left: 190px; }
        section.helpers .logos .item .data h1 {
          font-family: "officiana-serif-black-itc", sans-serif;
          font-size: 28px;
          font-weight: bold;
          margin: 0;
          text-align: left;
          padding: 0;
          color: #777575; }
          @media screen and (max-width: 560px) {
            section.helpers .logos .item .data h1 {
              font-size: 22px; } }
        section.helpers .logos .item .data p, section.helpers .logos .item .data section.inner article .meta, section.inner article section.helpers .logos .item .data .meta {
          font-size: 16px;
          margin: 10px 0;
          color: #777575; }
        @media screen and (max-width: 880px) {
          section.helpers .logos .item .data {
            padding: 0 40px 60px; } }
        section.helpers .logos .item .data span.error {
          font-weight: 300;
          color: #ef2100; }
        section.helpers .logos .item .data span.success {
          font-weight: 300;
          color: #00801b; }
      @media screen and (max-width: 560px) {
        section.helpers .logos .item {
          width: 100%; } }
    @media screen and (max-width: 950px) {
      section.helpers .logos {
        padding-left: 120px; } }
    @media screen and (max-width: 880px) {
      section.helpers .logos {
        padding-left: 150px; } }
    @media screen and (max-width: 760px) {
      section.helpers .logos {
        width: 100%;
        padding: 150px 20px 40px; } }
  section.helpers .tnx-txt {
    position: absolute;
    top: 110px;
    left: 50px;
    color: #777575; }
    section.helpers .tnx-txt h1 {
      margin: 0;
      padding: 0;
      position: relative;
      font-family: "officiana-serif-black-itc", sans-serif;
      font-weight: bold;
      font-size: 80px;
      line-height: 90px; }
      section.helpers .tnx-txt h1:after {
        content: '';
        position: absolute;
        width: 100%;
        bottom: 0;
        height: 7px;
        background: #777575;
        left: 0; }
    section.helpers .tnx-txt p, section.helpers .tnx-txt section.inner article .meta, section.inner article section.helpers .tnx-txt .meta {
      margin: 0;
      padding: 5px 0 0;
      font-size: 30px;
      font-family: "museo-sans-900", sans-serif; }
    @media screen and (max-width: 970px) {
      section.helpers .tnx-txt {
        left: 30px; } }
    @media screen and (max-width: 760px) {
      section.helpers .tnx-txt {
        -webkit-transform: translate(-50%, 0);
        -moz-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
        -o-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
        left: 50%;
        top: 10px; }
        section.helpers .tnx-txt h1 {
          font-size: 55px; }
        section.helpers .tnx-txt p, section.helpers .tnx-txt section.inner article .meta, section.inner article section.helpers .tnx-txt .meta {
          font-size: 22px; } }
  @media screen and (max-width: 970px) {
    section.helpers {
      padding: 60px 0 0; } }

section.news {
  background: #ffffff;
  position: relative;
  width: 100%;
  max-width: 1200px;
  padding: 70px 30px 0;
  margin: 120px auto 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  opacity: 0;
  -webkit-transition: all .5s ease-in;
  -moz-transition: all .5s ease-in;
  -o-transition: all .5s ease-in;
  transition: all .5s ease-in; }
  section.news.show {
    opacity: 1;
    margin-top: 20px; }
  section.news .inner {
    position: relative;
    margin: 0 auto;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 80px 20px;
    background: #C3E0E0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
    section.news .inner h2 {
      display: block;
      position: absolute;
      font-size: 30px;
      font-family: "museo-sans-900", sans-serif;
      font-weight: bold;
      margin: 0;
      top: -20px;
      left: 20px; }
      section.news .inner h2:after {
        content: '';
        display: block;
        position: absolute;
        left: -4px;
        right: -30px;
        height: 4px;
        background: #231F20;
        bottom: -25px; }
      @media screen and (max-width: 880px) {
        section.news .inner h2 {
          left: 40px; } }
      @media screen and (max-width: 560px) {
        section.news .inner h2 {
          left: 0;
          right: 0;
          margin: auto;
          width: 100%;
          max-width: 207px;
          text-align: center;
          font-size: 22px;
          top: -14px; }
          section.news .inner h2:after {
            right: 0;
            left: 0; } }
    section.news .inner article {
      float: left;
      width: 50%;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      margin-bottom: 20px; }
      section.news .inner article .img {
        width: 200px;
        height: 200px;
        background: transparent center center no-repeat;
        float: left; }
        @media screen and (max-width: 880px) {
          section.news .inner article .img {
            display: none; } }
      section.news .inner article .data {
        padding-left: 220px; }
        section.news .inner article .data h1 {
          font-family: "officiana-serif-black-itc", sans-serif;
          font-size: 28px;
          font-weight: bold;
          margin: 0;
          text-align: left;
          padding: 0; }
          @media screen and (max-width: 560px) {
            section.news .inner article .data h1 {
              font-size: 22px; } }
        section.news .inner article .data p, section.news section.inner article .data .meta {
          font-size: 16px;
          margin: 10px 0; }
        section.news .inner article .data ul {
          font-size: 16px;
          max-width: 640px;
          clear: both;
          float: right;
          margin: 10px 100px 10px 0;
          text-align: left;
          width: 100%;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box; }
        section.news .inner article .data span.error {
          font-weight: 300;
          color: #ef2100; }
        section.news .inner article .data span.success {
          font-weight: 300;
          color: #00801b; }
        @media screen and (max-width: 880px) {
          section.news .inner article .data {
            padding: 0 40px 60px; } }
      @media screen and (max-width: 560px) {
        section.news .inner article {
          width: 100%; } }
    @media screen and (max-width: 880px) {
      section.news .inner {
        padding: 80px 0; } }
  section.news.archive {
    padding-top: 195px; }
    section.news.archive .inner {
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      background: #ffffff; }
      section.news.archive .inner article {
        padding-bottom: 40px; }
        section.news.archive .inner article.hidden {
          display: none; }
        section.news.archive .inner article ul {
          font-size: 16px;
          max-width: 640px;
          clear: both;
          float: right;
          margin: 10px 100px 10px 0;
          text-align: left;
          width: 100%;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box; }
  @media screen and (max-width: 970px) {
    section.news {
      padding: 60px 0 0; } }

section.inner {
  width: 100%;
  max-width: 1100px;
  margin: 150px auto 0; }
  section.inner article {
    margin: 0 auto;
    width: 100%;
    position: relative;
    font-size: 16px; }
    section.inner article h1 {
      font-weight: bold;
      color: #F47920;
      font-size: 30px;
      clear: both;
      max-width: 640px;
      width: 100%;
      float: right;
      font-family: 'museo-sans-900', sans-serif;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      margin: 20px 100px 5px 0; }
    section.inner article p, section.inner article .meta, section.inner article table {
      font-size: 16px;
      max-width: 640px;
      clear: both;
      float: right;
      margin: 10px 100px 10px 0;
      text-align: left;
      width: 100%;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box; }
    section.inner article .meta {
      margin-top: 20px;
      margin-bottom: 30px; }
      section.inner article .meta iframe, section.inner article .meta #draugiemLike {
        display: inline-block; }
      section.inner article .meta #draugiemLike {
        margin-left: 15px; }
    section.inner article img {
      max-width: 100%; }
    section.inner article .header-image {
      width: 100%;
      float: right;
      padding: 10px 0;
      text-align: left;
      max-width: 640px;
      margin: 10px 100px 10px 0; }
    section.inner article .inline-image {
      text-align: center;
      max-width: 640px;
      clear: both;
      float: right;
      margin: 10px 100px 10px 0;
      width: 100%; }
    section.inner article ul {
      font-size: 16px;
      max-width: 640px;
      clear: both;
      float: right;
      margin: 10px 100px 10px 0;
      text-align: left;
      width: 100%;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box; }
    @media screen and (max-width: 960px) {
      section.inner article p, section.inner article .meta, section.inner article h1 {
        margin-left: auto;
        margin-right: auto;
        padding: 0 40px;
        float: none; }
      section.inner article .header-image {
        width: 100%;
        float: none;
        margin: 0 auto; } }
    section.inner article span.error {
      font-weight: 300;
      color: #ef2100;
      width: 100%;
      text-align: center;
      display: inline-block; }
    section.inner article span.success {
      font-weight: 300;
      color: #00801b;
      width: 100%;
      text-align: center;
      display: inline-block; }

footer {
  background: #F47920;
  position: relative;
  width: 100%;
  margin: 60px auto 0; }
  footer section {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    padding: 0 50px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
    footer section .col {
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      -moz-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      color: #ffffff;
      font-size: 16px; }
      footer section .col.middle {
        background: #ED6A6B;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 150px;
        -moz-box-flex: 0;
        -ms-flex: 0 0 150px;
        flex: 0 0 150px;
        position: relative; }
        footer section .col.middle .soc-icons {
          position: absolute;
          bottom: 80px;
          width: 100%;
          text-align: center; }
          footer section .col.middle .soc-icons .soc-icon {
            margin-top: 20px;
            width: 100%; }
      footer section .col.first {
        padding-bottom: 50px; }
      footer section .col.form {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex; }
        footer section .col.form .subc {
          -webkit-box-flex: 1;
          -webkit-flex: 1;
          -moz-box-flex: 1;
          -ms-flex: 1;
          flex: 1; }
          footer section .col.form .subc.form {
            background: #EFA624;
            -webkit-box-flex: 0;
            -webkit-flex: 0 0 100%;
            -moz-box-flex: 0;
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            float: right;
            position: relative; }
            footer section .col.form .subc.form form {
              position: absolute;
              display: block;
              bottom: 50px;
              padding: 0 20px; }
              footer section .col.form .subc.form form input, footer section .col.form .subc.form form textarea {
                border: 1px solid #ffffff;
                background: transparent;
                display: block;
                font-size: 16px;
                position: relative;
                color: #ffffff;
                padding: 7px 12px 6px 7px;
                outline: none;
                font-family: "museo-sans-300", sans-serif;
                margin: 0 0 10px;
                width: 100%;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box; }
                footer section .col.form .subc.form form input.error, footer section .col.form .subc.form form textarea.error {
                  color: #ef2100;
                  border-color: #ef2100; }
              footer section .col.form .subc.form form button {
                background: transparent;
                margin-top: 5px; }
              footer section .col.form .subc.form form span.error {
                font-weight: 300;
                color: #ef2100; }
              footer section .col.form .subc.form form span.success {
                font-weight: 300;
                color: #00801b; }
      footer section .col h2 {
        font-size: 30px;
        font-family: "museo-sans-900", sans-serif;
        font-weight: bold;
        margin: 50px 0 0;
        color: #ffffff;
        position: relative;
        display: inline-block; }
        footer section .col h2:after {
          content: '';
          display: block;
          position: absolute;
          left: -4px;
          right: -30px;
          height: 4px;
          background: #ffffff;
          bottom: -25px; }
      footer section .col h3 {
        color: #ffffff;
        font-family: "officiana-serif-black-itc", sans-serif;
        font-size: 30px;
        font-weight: bold;
        margin: 40px 0 30px; }
      footer section .col strong {
        font-weight: 500;
        font-family: "museo-sans-500", sans-serif; }
      footer section .col a {
        color: #ffffff; }
        footer section .col a:hover {
          text-decoration: none; }
    @media screen and (max-width: 970px) {
      footer section {
        padding: 0 0 0 40px; } }
    @media screen and (max-width: 670px) {
      footer section {
        display: block;
        padding: 0 0 95px; }
        footer section .col {
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box; }
          footer section .col.first {
            display: block;
            width: 100%;
            -webkit-box-flex: 1;
            -webkit-flex: auto;
            -moz-box-flex: 1;
            -ms-flex: auto;
            flex: auto;
            padding: 0 30px 40px; }
            footer section .col.first h2 {
              font-size: 22px; }
            footer section .col.first h3 {
              font-size: 22px; }
          footer section .col.form {
            display: block;
            width: 100%;
            -webkit-box-flex: 1;
            -webkit-flex: auto;
            -moz-box-flex: 1;
            -ms-flex: auto;
            flex: auto;
            padding: 0 10px;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box; }
            footer section .col.form .subc {
              -webkit-box-flex: 0;
              -webkit-flex: 0;
              -moz-box-flex: 0;
              -ms-flex: 0;
              flex: 0;
              -webkit-box-sizing: border-box;
              -moz-box-sizing: border-box;
              box-sizing: border-box; }
              footer section .col.form .subc.form {
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
                padding-top: 35px;
                padding-bottom: 35px;
                width: 100%;
                -webkit-box-flex: 0;
                -webkit-flex: 0;
                -moz-box-flex: 0;
                -ms-flex: 0;
                flex: 0; }
                footer section .col.form .subc.form form {
                  position: relative;
                  float: none;
                  bottom: auto; }
          footer section .col.middle {
            display: block;
            position: absolute;
            -webkit-box-flex: 1;
            -webkit-flex: auto;
            -moz-box-flex: 1;
            -ms-flex: auto;
            flex: auto;
            bottom: 0;
            height: 95px;
            text-align: center;
            left: 10px;
            right: 10px; }
            footer section .col.middle .soc-icons {
              position: relative;
              bottom: auto; }
              footer section .col.middle .soc-icons .soc-icon {
                position: relative;
                display: inline-block;
                width: 65px;
                margin-top: 26px; } }

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #ffffff; }

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #ffffff;
  opacity: 1; }

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #ffffff;
  opacity: 1; }

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #ffffff; }

.soc-icon {
  background: transparent url("../img/soc-icons.png") center 0 no-repeat;
  width: 55px;
  height: 44px;
  display: inline-block; }
  .soc-icon.tw {
    background-position: center 4px; }
  .soc-icon.fb {
    background-position: center -135px; }
  .soc-icon.fl {
    background-position: center -65px; }
  .soc-icon.dr {
    background-position: center bottom; }

.admin-nav {
  background: rgba(35, 31, 32, 0.85);
  color: #ffffff;
  position: absolute;
  z-index: 10000;
  top: 0;
  text-align: center;
  display: block;
  width: 200px;
  padding: 5px 15px;
  left: 50%;
  margin-left: -100px;
  -webkit-border-bottom-left-radius: 10px;
  -moz-border-radius-bottomleft: 10px;
  border-bottom-left-radius: 10px;
  -webkit-border-bottom-right-radius: 10px;
  -moz-border-radius-bottomright: 10px;
  border-bottom-right-radius: 10px; }
  .admin-nav a {
    color: #ffffff;
    font-weight: bold;
    text-decoration: none; }
    .admin-nav a:hover {
      text-decoration: underline; }

.loader {
  display: block;
  margin: 0 auto;
  width: 40px;
  height: 40px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 0 #F47920 solid;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-animation: loader 1.25s infinite;
  -moz-animation: loader 1.25s infinite;
  -o-animation: loader 1.25s infinite;
  animation: loader 1.25s infinite;
  -webkit-box-shadow: #F47920 0 0 2px 0;
  -moz-box-shadow: #F47920 0 0 2px 0;
  box-shadow: #F47920 0 0 2px 0;
  opacity: 0;
  -webkit-transition: opacity 1.25s ease;
  -moz-transition: opacity 1.25s ease;
  -o-transition: opacity 1.25s ease;
  transition: opacity 1.25s ease; }
  .loader.on {
    opacity: 1; }

.forms {
  margin-top: 20px; }
  .forms .form-group {
    float: right;
    max-width: 750px;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    clear: both;
    margin-bottom: 15px;
    min-height: 34px; }
    .forms .form-group label {
      display: inline-block;
      width: 27%;
      vertical-align: middle;
      font-size: 16px;
      font-family: "museo-sans-300", sans-serif;
      color: #231F20;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      float: left; }
      .forms .form-group label.radio {
        float: none;
        width: auto;
        position: relative;
        padding-left: 44px;
        padding-right: 20px;
        padding-top: 5px; }
        .forms .form-group label.radio:before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 34px;
          height: 34px;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          border: 1px solid #231F20; }
        .forms .form-group label.radio:after {
          content: '';
          display: block;
          position: absolute;
          top: 5px;
          left: 5px;
          width: 24px;
          height: 24px;
          -webkit-transition: all .25s ease-in;
          -moz-transition: all .25s ease-in;
          -o-transition: all .25s ease-in;
          transition: all .25s ease-in; }
    .forms .form-group input, .forms .form-group textarea {
      border: 1px solid #231F20;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      width: 73%;
      display: inline-block;
      float: right;
      font-size: 16px;
      font-family: "museo-sans-300", sans-serif;
      padding: 5px 15px;
      vertical-align: middle; }
    .forms .form-group input {
      height: 34px; }
    .forms .form-group input[type=radio] {
      display: none; }
      .forms .form-group input[type=radio]:checked + label:after {
        background: #231F20; }
    .forms .form-group button {
      width: auto;
      background: transparent; }
    .forms .form-group textarea {
      height: 105px; }
    .forms .form-group.has-error input, .forms .form-group.has-error textarea {
      border-color: #ef2100; }
    .forms .form-group.has-error label {
      color: #ef2100; }

@-webkit-keyframes loader {
  0% {
    border-width: 0;
    -webkit-box-shadow: #F47920 0 0 2px 0;
    box-shadow: #F47920 0 0 2px 0; }
  50% {
    border-width: 20px;
    -webkit-box-shadow: #F47920 0 0 40px 0;
    box-shadow: #F47920 0 0 40px 0; }
  100% {
    border-width: 0;
    -webkit-box-shadow: #F47920 0 0 2px 0;
    box-shadow: #F47920 0 0 2px 0; } }

@-moz-keyframes loader {
  0% {
    border-width: 0;
    -moz-box-shadow: #F47920 0 0 2px 0;
    box-shadow: #F47920 0 0 2px 0; }
  50% {
    border-width: 20px;
    -moz-box-shadow: #F47920 0 0 40px 0;
    box-shadow: #F47920 0 0 40px 0; }
  100% {
    border-width: 0;
    -moz-box-shadow: #F47920 0 0 2px 0;
    box-shadow: #F47920 0 0 2px 0; } }

@-o-keyframes loader {
  0% {
    border-width: 0;
    box-shadow: #F47920 0 0 2px 0; }
  50% {
    border-width: 20px;
    box-shadow: #F47920 0 0 40px 0; }
  100% {
    border-width: 0;
    box-shadow: #F47920 0 0 2px 0; } }

@keyframes loader {
  0% {
    border-width: 0;
    -webkit-box-shadow: #F47920 0 0 2px 0;
    -moz-box-shadow: #F47920 0 0 2px 0;
    box-shadow: #F47920 0 0 2px 0; }
  50% {
    border-width: 20px;
    -webkit-box-shadow: #F47920 0 0 40px 0;
    -moz-box-shadow: #F47920 0 0 40px 0;
    box-shadow: #F47920 0 0 40px 0; }
  100% {
    border-width: 0;
    -webkit-box-shadow: #F47920 0 0 2px 0;
    -moz-box-shadow: #F47920 0 0 2px 0;
    box-shadow: #F47920 0 0 2px 0; } }

.clearfix {
  clear: both; }

/*# sourceMappingURL=app.css.map */
